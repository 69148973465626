<template>
  <v-app-bar variant="compact" :app="true" color="var(--color-app-bar)">
    <template v-for="(item, index) in getNavItems()">
      <v-toolbar-items v-if="item.v4lOnly" :key="index">
        <v-btn
          v-if="item.badges && item.badgeValue > 0"
          variant="text"
          :aria-label="item.a11y"
          @click="navigate(item, index)"
        >
          <v-badge :content="item.badgeValue" bottom right> </v-badge>
          <div :class="getNavClass(item.index)" v-text="item.name" />
        </v-btn>
        <v-btn
          v-else
          variant="text"
          :aria-label="item.a11y"
          @click="navigate(item, index)"
        >
          <div :class="getNavClass(item.index)" v-text="item.name" />
        </v-btn>
      </v-toolbar-items>
    </template>
    <v-spacer />
    <HelpInformation
      color="var(--color-brand)"
      style="padding-right: 0.3vw; padding-left: 0.3vw"
    />
    <v-avatar variant="outlined" color="var(--color-brand)">
      <span color="var(--color-white)">{{ getAvatarDisplayName(true) }}</span>
    </v-avatar>
    <v-btn
      icon="mdi-dots-vertical"
      :class="getNavClass(10)"
      size="small"
      @click="doSettings()"
    />
    <!--
    <v-btn
      icon="mdi-logout-variant"
      :class="getNavClass(20)"
      size="small"
      @click="userLogout()"
    />
    -->
  </v-app-bar>
</template>

<script>
import adminStore from '@/stores/adminstore'
import { mapActions, mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'NavigationSystem',
  components: {
    //  NavIcon: () => import('@/components/menues/NavIcon'),
    HelpInformation: defineAsyncComponent(
      () => import('@/components/menues/HelpInformation.vue')
    )
  },
  props: {
    location: { type: String, default: 'top' }
  },
  computed: {},
  data() {
    return {
      activeMenyItem: -1,
      countUnassessed: 0
    }
  },
  mounted() {
    this.setActiveNavItem(-1)
  },
  methods: {
    ...mapActions(adminStore, ['logout', 'setActiveNavItem']),
    ...mapState(adminStore, [
      'getDisplayName',
      'getActiveNavItem',
      'getOnlyV4L',
      'getActiveLearnerInfo',
      'getIdToken'
    ]),
    getName() {
      return this.getDisplayName()
    },
    getActiveNav() {
      console.log('getActiveNav(%j)', this.getActiveNavItem())
      return this.getActiveNavItem().index
    },
    getAvatarDisplayName(avatar) {
      if (avatar) {
        let name = this.getDisplayName() || 'No Name'
        let nameParts = name.split(' ')
        console.log('NAMEPARTS (%s) (%s)', nameParts[0], nameParts[1])
        if (nameParts.length > 1) {
          return (
            nameParts[0].substring(0, 1).toUpperCase() +
            nameParts[1].substring(0, 1).toUpperCase()
          )
        } else if (nameParts.length > 0) {
          return nameParts[0].substring(0, 1).toUpperCase()
        } else {
          return ''
        }
      } else {
        return this.getDisplayName()
      }
    },
    getNavClass(navIndex) {
      console.log('GET NAV CLASS (%s) (%s)', this.getActiveNav(), navIndex)
      if (this.getActiveNav() === navIndex) {
        return 'labelactive'
      } else {
        return 'label'
      }
    },
    navigate(item, itemIndex) {
      console.log('NAVIGATE got called !', item)
      // item.index = itemIndex;
      this.setActiveNavItem(item)
      this.$router
        .push({
          name: item.pathName
        })
        .catch((err) => {
          console.log(err)
        })
    },
    doSettings() {
      var newPath = '/settings/'
      console.log('++++++++++ NEW PATH (' + newPath + ')')
      this.setActiveNavItem(10)
      this.activeMenyItem = 10
      this.$router
        .push({
          name: 'EducatorSettings'
        })
        .catch(() => {})
    },
    getNavItems() {
      return [
        {
          /* 5 V4L */
          index: 5,
          name: this.$t('nav.nav5'),
          a11y: this.$t('nav.nav5'),
          pathName: 'CurriculumView',
          v4lOnly: true
        },
        {
          /* 0 */
          index: 0,
          name: this.$t('nav.nav0'),
          a11y: this.$t('nav.nav0'),
          pathName: 'Contribution',
          v4lOnly: !this.getOnlyV4L()
        },
        {
          /* 1 */
          index: 1,
          name: this.$t('nav.nav1'),
          a11y: this.$t('nav.nav1'),
          pathName: 'SelfAssessment',
          v4lOnly: !this.getOnlyV4L()
        },
        /*
        {
          / * 2 * /
          index: 2,
          name: this.$t('nav.nav2'),
          a11y: this.$t('nav.nav2'),
          pathName: 'Homework',
          v4lOnly: this.getOnlyV4L()
        },
        */

        {
          /* 7 V4L */
          index: 7,
          name: this.$t('nav.nav7'),
          a11y: this.$t('nav.nav7'),
          pathName: 'CreateAssignment',
          v4lOnly: true
        },
        {
          /* 8 V4L */
          index: 8,
          name: this.$t('nav.nav8'),
          a11y: this.$t('nav.nav8'),
          pathName: 'EducatorAssessAssignments',
          badges: true,
          badgeValue: this.countUnassessed,
          v4lOnly: true
        },
        {
          /* 6 V4L */
          index: 6,
          name: this.$t('nav.nav9'),
          a11y: this.$t('nav.nav9'),
          pathName: 'TodaysActiveAssignments',
          v4lOnly: true
        },

        {
          /* 3 */
          index: 3,
          name: this.$t('nav.nav3'),
          a11y: this.$t('nav.nav3'),
          pathName: 'UserSettings',
          v4lOnly: !this.getOnlyV4L()
        }
      ]
    },
    getNumberPeerReviewAssignments() {
      let learnerInfo = this.getActiveLearnerInfo() || {}
      let peerReviewAssignments = learnerInfo.assignmentsForPeerReview || []
      console.log(
        'getNumberPeerReviewAssignments got called (%s) ',
        peerReviewAssignments.length
      )
      console.log(peerReviewAssignments)
      return peerReviewAssignments.length
    },
    getNumberActiveWrittenAssignment() {
      //  console.log('getNumberActiveWrittenAssignments!')
      let now = this.$date()
      let learner = this.getActiveLearnerInfo()
      let subjects = learner.learnerHasSubject || []
      let activeWrittenAssignments = []
      // console.log('subjects.length(%s)', subjects.length)
      for (let i = 0; i < subjects.length; i++) {
        let subject = subjects[i]
        //  console.log(subject)
        let commonSubjectGroup = subject.learnerHasCommonSubjectGroup || {}
        let writtenAssignments = commonSubjectGroup.hasWrittenAssignment || []
        // console.log('Written Assignments length(%s)', writtenAssignments.length)
        for (let j = 0; j < writtenAssignments.length; j++) {
          let writtenAssignment = writtenAssignments[j]
          if (
            now.isBetween(
              this.$date(writtenAssignment.writtenassignment_start_date),
              this.$date(writtenAssignment.writtenassignment_end_date)
            )
          ) {
            activeWrittenAssignments.push(writtenAssignment)
          }
        }
      }
      return activeWrittenAssignments.length || 0
    },
    userLogout() {
      let token = this.getIdToken()
      console.log('logout got called !')
      localStorage.removeItem('lid')
      localStorage.removeItem('sid')
      localStorage.removeItem('ssa')
      this.setActiveNavItem(20)
      this.activeMenyItem = 20
      this.logout()

      //          window.open('https://auth.dataporten.no/logout', '_self', true)
      window.open(
        `https://auth.dataporten.no/openid/endsession?post_logout_redirect_uri=https://www.v4l.no/utlogget&id_token_hint=${token}`,
        '_self',
        true
      )
    },
    logout2() {
      console.log('logout got called !')
      localStorage.removeItem('lid')
      localStorage.removeItem('sid')
      localStorage.removeItem('ssa')
      this.logout()
        .then(() => {})
        .catch((error) => {
          console.error('PROBLEMS LOGGING OUT (%o)', error)
        })
        .finally(() => {
          this.$router
            .push({
              name: 'StartPage'
            })
            .catch(() => {})
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-wrapper {
  border-top: 1px solid var(--color-main-header);
  padding: 0.5rem 0 0.5rem 0;
}
.nav {
  // display: flex;
  align-items: center;
}
.nav-item {
  // flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  &.active {
    color: var(--color-brand-100);
  }
}
.icon {
  margin-bottom: 0.5rem;
}
.label {
  display: flex;
  font-family: var(--text-font-heading);
  font-variation-settings: 'wght' 575;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.02rem;
  text-decoration: none;
  cursor: pointer;
}

.labelactive {
  display: flex;
  font-family: var(--text-font-heading);
  font-size: 1.1rem;
  text-align: center;
  letter-spacing: 0.05rem;
  font-weight: 800;
  color: var(--color-white);
}

.active {
  display: flex;
}
</style>
