/* SA EDUCATOR */

import { createRouter, createWebHistory } from 'vue-router'
import jwt from 'jsonwebtoken'

import LandingPage from '@/views/LandingPage.vue'
import adminStore from '@/stores/adminstore.js'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/landingpage',
      name: 'LandingPage',
      component: LandingPage
    },
    {
      path: '/chat',
      name: 'ChatDialog',
      component: () => import('@/components/chatDialog/ChatDialog.vue')
    },
    {
      path: '/mustlogin',
      name: 'MustLogin',
      component: () => import('@/views/MustLogin.vue')
    },
    {
      path: '/logout',
      name: 'LogOut',
      component: () => import('@/views/LogOut.vue')
    },
    {
      path: '/callback',
      name: 'Callback',
      props: (route) => ({
        query: route.query.code
      }),
      component: () => import('@/views/LoginCallback.vue')
    },
    {
      path: '/loggedin',
      name: 'LoggedIn',
      component: () => import('@/views/LoggedIn.vue')
    },
    {
      path: '/noschool',
      name: 'NoSchoolRegistered',
      component: () => import('@/views/NoSchoolRegistered.vue')
    },
    {
      path: '/competencyassessment',
      name: 'CompetencyAssessmentSummary',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/CompetencyAssessmentSummary.vue')
    },
    {
      path: '/sso',
      name: 'SilentLogin',
      beforeEnter: ssoLogin,
      component: () => import('@/views/SilentLogin.vue')
    },
    {
      path: '/competencyassessmentdoughnut',
      name: 'CompetencyAssessmentSummaryDoughnut',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/CompetencyAssessmentSummaryDoughnut.vue')
    },
    {
      path: '/error/:errHeading/:errMessage',
      name: 'Error',
      component: () => import('@/views/Error.vue'),
      props: true
    },
    {
      path: '/errornoeducator',
      name: 'ErrorNoEducator',
      component: () => import('@/views/ErrorNoEducator.vue')
    },
    {
      path: '/settings',
      name: 'EducatorSettings',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/EducatorSettings.vue')
    },
    {
      path: '/learnerview',
      name: 'LearnerViewInput',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/LearnerViewInput.vue')
    },
    {
      path: '/',
      name: 'V4LStartPage',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/V4LStartPage.vue')
    },
    {
      path: '/subjects',
      name: 'Subjects',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/SchoolSubjects.vue')
    },
    {
      path: '/adaptations',
      name: 'EducatorAdaptations',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/EducatorAdaptations.vue')
    },
    {
      path: '/check',
      name: 'CheckSessionLogin',
      component: () => import('@/views/CheckSessionLogin.vue')
    },
    /* V4L */
    {
      path: '/assessassignments',
      name: 'EducatorAssessAssignments', // tile 1
      beforeEnter: requireAuthentication,
      component: () => import('@/views/EducatorAssessAssignments.vue')
    },
    {
      path: '/resultpeerreview',
      name: 'ResultFromPeerReviewOfAssignments', // tile 2
      beforeEnter: requireAuthentication,
      component: () => import('@/views/ResultFromPeerReviewOfAssignments.vue')
    },
    {
      path: '/inprocess',
      name: 'TodaysActiveAssignments', // tile 3
      beforeEnter: requireAuthentication,
      component: () => import('@/views/TodaysActiveAssignments.vue')
    },
    {
      path: '/createassignment',
      name: 'CreateAssignment',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/CreateAssignment.vue')
    },
    {
      path: '/assesswrittentext',
      name: 'AssessText',
      beforeEnter: requireAuthentication,
      component: () => import('@/components/assessment/AssessText.vue')
    },
    {
      path: '/curriculum',
      name: 'CurriculumView',
      beforeEnter: requireAuthentication,
      component: () => import('@/components/curriculum/CurriculumView.vue')
    } /*,

    {
      path: '*',
      name: 'ErrorPage',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import('@/views/ErrorPage.vue')
    }
    */
  ]
})

function requireAuthentication(to, from, next) {
  let aStore = adminStore()
  console.log(
    '========= REQUIRE AUTHENTICATION ======== to: ' +
      to.name +
      ' - ' +
      to.fullPath +
      ' FROM:' +
      from.name
  )
  //  console.log('STORE TEST:')
  //  console.log(store.getters.getVersion)

  if (checkSSO()) {
    console.log('---------- REDIRECTING TO CHECK SESSION LOGIN --------')
    next({
      //      path: '/mustlogin'
      name: 'CheckSessionLogin'
    })
  } else if (!aStore.loggedin) {
    console.log('---------- REDIRECTING TO MUST LOGIN --------')
    aStore.$reset()
    aStore.logout()
    next({
      path: '/mustlogin'
    })
  } else {
    // let nowSeconds = Math.floor(Date.now() / 1000)
    // store.commit('setAccessTokenExpires', nowSeconds + 3600 * 24) // add 24 hour to expires
    console.log('>>>>>> We are authenticated >>>>>>')
    next()
  }
}

function checkSSO() {
  let aStore = adminStore()
  let ssoData = localStorage.getItem('lid') || ''
  console.log('SSO DATA: (%s)', aStore.loggedin)
  // console.log(ssoData)
  if (ssoData !== '' && !aStore.loggedin) {
    console.log('** WE HAVE SSO DATA ** ')
    return true
  }
  return false
}

function ssoLogin(to, from, next) {
  console.log('SSO LOGIN CHECK')
  let aStore = adminStore()
  checkSSO_NEW()
    .then((ssoRES) => {
      console.log('RES FROM SSO CHECK')
      console.log(ssoRES)
      if (ssoRES.success) {
        aStore.loginprocess = true
        aStore.loggedin = false
        next()
      } else {
        next({ name: 'MustLogin' })
      }
    })
    .catch((error) => {
      console.info('*** ERROR *** SSO CHECK: ')
      console.log(error)
      next({ name: 'MustLogin' })
    })
}

function checkSSO_NEW() {
  return new Promise((resolve) => {
    console.log('CHECK SSO')
    let aStore = adminStore()
    aStore
      .checkSSO()
      .then((ssoRES) => {
        console.info('***** AUTH SSO WAS **** (%j)', ssoRES)
        let aToken = ssoRES.access_token
        // let tokenExpiresIn = result.expiresIn
        let idToken = ssoRES.id_token
        //   let jwtToken = jwt_decode(idToken)
        var jwtToken = jwt.decode(idToken)
        console.log('=== jwtToken (%J)', jwtToken)
        // console.log(jwtToken)
        // let tokenExpires = jwtToken.exp
        aStore.accessToken = aToken
        console.log('ACCESSTOKEN (%s)', aStore.accessToken)
        let nowSeconds = Math.floor(Date.now()) //  / 1000)
        aStore.accessTokenExpires = nowSeconds + 3600 * 12 //  + 3600 * 24 // + 24 houres to the expires.
        aStore.idtoken = idToken
        let date = new Date(0)
        let expires = date.setUTCSeconds(aStore.accessTokenExpires)
        console.log(
          'isLoggedIn expires(%o) > now(%o) test(%o)',
          expires,
          Date.now(),
          expires > Date.now()
        )
        aStore.setAccessToken(aToken)
        localStorage.setItem('tokenexpire', expires)
        aStore.loggedin = true
        resolve({ success: true })
      })
      .catch((err) => {
        console.log('***** AUTH SSO ERROR **** (%j)', err)
        resolve({ success: false, error: true, errorMsg: err })
      })
  }) // promise
  /*
  let ssoData = localStorage.getItem('lid')
  //console.log(ssoData)
  if (
    ssoData !== undefined &&
    ssoData !== null &&
    ssoData !== '' &&
    !aStore.loggedin
  ) {
    console.log('** WE HAVE SSO DATA ** ')
    return true
  }
  return false
  */
}

export default router
