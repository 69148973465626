/* Class and set of functions for communication with Feide and Dataporten */
import dayjs from '@/plugins/dayjs.js'
// import os from 'os'

const GREPVersion =
  '/kl06/v201906/' /* REMEBER TO UPDATE sa-learner and GREP functions ! */
const GREPDataType = 'fagkoder'
const GREPKompetanseMaalSett = 'kompetansemaalsett-lk20'
const GREPStatusPublisert =
  'https://data.udir.no/kl06/v201906/status/status_publisert'

export class UdirTools {
  constructor() {
    console.log('---------------UdirTools----------- Constructor ')
    this.udirAPIServer = 'https://data.udir.no'
  }

  getUdirEnrichUGroup(uGroups) {
    // console.log('getUdirEnrichUGroup got called')
    // console.log(JSON.stringify(uGroups))
    return new Promise((resolve) => {
      let enrichedGroups = []
      if (uGroups.length === 0) {
        console.warn('NO UGROUPS')
        resolve([])
      }
      if (uGroups[0].go_type) {
        // WE HAVE K13
        /* WE HAVE HigherEducations, since HE have "membership" structure 
           And no codes for accessing GREP or curiculum information.
        */
        // console.log('uGROUPS LENGTH(' + uGroups.length + ')')
        let isProcessing = []
        for (var i = 0; i < uGroups.length; i++) {
          // uGroups.forEach((value, index) => {
          let item = uGroups[i]
          if (item === undefined) {
            continue
          }
          let type = item.type || {}
          if (type === 'fc:fs:prg' || type === 'fc:fs:emne') {
            // No GREP information for HE
            resolve([])
          }

          //  console.log(JSON.stringify(item))
          let lang = localStorage.getItem('lang')
          let grepItem = item.grep
          if (grepItem === undefined) {
            continue
          }
          let code = item.grep.code
          if (code === undefined) {
            continue
          }
          this.getUdirFagKodeFromFeideGrepCode(code, i, lang)
            .then((res) => {
              let value = {}
              // console.log(JSON.stringify(res))
              let result = res.result
              let index = res.index
              isProcessing.push(index)
              //    console.log('RESULT IS ')
              //    console.log(JSON.stringify(res))
              if (res.result.length === 0) {
                console.warn(
                  '====== NO DATA FROM OPPLAERINGSFAG ==(%s)==',
                  code
                )
              } else {
                /*    console.log(
                '==== NEW KMS (' +
                  result +
                  ') index(' +
                  index +
                  ') i(' +
                  i +
                  ') length(' +
                  uGroups.length +
                  ')'
              )
              console.log(
                '===== NEW KMS isProcessing (' +
                  isProcessing.length +
                  ') uGroups (' +
                  uGroups.length +
                  ')'
              )
              */
                value = item
                value.kmsKode = result
                enrichedGroups.push(value)
              }
              if (isProcessing.length === uGroups.length) {
                // console.log('++++++++ getUdirEnrichUGroup +++++ COMPLETED')
                // console.log(enrichedGroups)
                resolve(enrichedGroups)
              }
            })
            .catch((error) => {
              console.error(
                '*** ERROR *** this.getUdirFagKodeFromFeideGrepCode had some problems',
                error
              )
              // reject(new Error('*** ERROR *** this.getUdirFagKodeFromFeideGrepCode had some problems', error))
            })
        } // for
      } else {
        // WE HAVE HE since they do not have go_type
        console.warn('Higher Education')
        resolve([])
      }
    })
  }

  async getUdirFagKodeFromFeideGrepCode(code, index, currentLang) {
    return new Promise((resolve, reject) => {
      // console.log('getUdirFagKodeFromFeideGrepCode got called : ' + code)
      let queryRes = {}
      let notPublisert = false
      let codeQuery = GREPDataType + '/' + code
      /* console.info(
        'QUERY IS: (%s)',
        this.udirAPIServer + GREPVersion + GREPDataType + '/' + code
      )
      */
      this.getHTTPUdirLanguage(
        this.udirAPIServer + GREPVersion + codeQuery,
        currentLang
      )
        .then((response) => {
          queryRes = response
          // from http://data.udir.no/kl06/MAT0010
          /*
          console.info(
            'QUERY WAS: (%s)',
            this.udirAPIServer + GREPVersion + GREPDataType + '/' + code
          )
          console.info(
            '====== RESPONSE ====== opplaeringsfag (%s) (%i), (%s)',
            code,
            index,
            currentLang
          )
          */
          //  console.log(response.data)
          let opplaeringsfag = response.data.opplaeringsfag || []
          //  console.log('OPPLÆRINGSFAG LENGTH (%i)', opplaeringsfag.length)
          if (opplaeringsfag.length === 0) {
            // FAK0007 is not a "opplaeringsfag"
            resolve({
              result: [],
              index: index
            })
          } else {
            let isPublisert = false
            for (let i = 0; i < opplaeringsfag.length; i++) {
              //            opplaeringsfag.forEach(value => {
              let value = opplaeringsfag[i]
              // console.log('=== STATUS CODE (%s)', value.status)
              if (checkValidOpplaeringsfag(value)) {
                notPublisert = false
                isPublisert = true
                this.getUdirFagKode(value)
                  .then((udirkode) => {
                    isPublisert = true
                    resolve({
                      result: udirkode,
                      index: index
                    })
                    return
                  })
                  .catch((error) => {
                    reject(new Error(error))
                  })
              }
            } // for
            if (!isPublisert) {
              /* Only if none of the previous opplaeringsfagene is published. ie. all is not published, and are replaced*/
              //    console.log('>>>>>>>>>>> NOT PUBLISERT >>>>>>>>>>>>>> (%s)', code)
              //    console.log('>>>>>>>>>>>>>>>>>> %s <<<<<<<<<<<<<<', notPublisert)
              //    console.log(JSON.stringify(queryRes))
              /* If none of the 'opplaeringsfag' is publised, it means that an old code has been used. And we should check for erstattes-av instead. */
              /* If we get here - it means that the "resolve" or "reject" has been triggered, and that we should look for 'erstattes-av' */
              let erstattesAv = queryRes.data['erstattes-av'] || []
              let erstattesUrl = ''
              //    console.log('ERSTATTES AV LENGTH (%i)', erstattesAv.length)
              for (let i = 0; i < erstattesAv.length; i++) {
                // erstattesAv.forEach(value => {
                let value = erstattesAv[i]
                if (value.status === GREPStatusPublisert) {
                  erstattesUrl = value['url-data']
                }
              } // forEach
              //    console.log('ERSTATTTES AV URL (%s)', erstattesUrl)
              if (erstattesUrl !== '') {
                this.getHTTPUdirLanguage(erstattesUrl, currentLang)
                  .then((updateResult) => {
                    //       console.log('========== UPDATE RESULT (%s)', erstattesUrl)
                    //       console.log(JSON.stringify(updateResult))
                    if (updateResult.data === null) {
                      resolve({
                        result: [],
                        index: index
                      })
                    } else {
                      let opplaeringsfag =
                        updateResult.data.opplaeringsfag || []
                      /*      console.log(
                        'OPPLÆRINGSFAG OPPDATERT LENGTH (%i)',
                        opplaeringsfag.length
                      )
                      */
                      if (opplaeringsfag.length === 0) {
                        // FAK0007 is not a "opplaeringsfag"
                        resolve({
                          result: [],
                          index: index
                        })
                      } else {
                        notPublisert = false
                        isPublisert = false
                        for (let i = 0; i < opplaeringsfag.length; i++) {
                          // opplaeringsfag.forEach(value => {
                          let value = opplaeringsfag[i]
                          //       console.log('=== STATUS CODE (%s)', value.status)
                          if (value.status === GREPStatusPublisert) {
                            notPublisert = false
                            isPublisert = true
                            this.getUdirFagKode(value)
                              .then((udirkode) => {
                                resolve({
                                  result: udirkode,
                                  index: index
                                })
                              })
                              .catch((error) => {
                                reject(new Error(error))
                              })
                          } // if publisert
                        } // foreach
                      } // else length >0
                    } // else has resultData
                  }) //then HTTP
                  .catch((error) => {
                    console.error(
                      '*** ERROR *** getUdirFagKodeFromFeideGrepCode replaced with had problems:',
                      error
                    )
                    reject(
                      new Error(
                        '*** ERROR *** getUdirFagKodeFromFeideGrepCode replaced with had problems:',
                        error
                      )
                    )
                  })
              } // if has erstatterUrl
            } // if is publisert
          } // if opplaeringsfag
        }) // then first HTTP
        .catch((error) => {
          console.error(
            '*** ERROR *** getUdirFagKodeFromFeideGrepCode had some problems : ' +
              error
          )
          reject(
            new Error(
              '*** ERROR *** getUdirFagKodeFromFeideGrepCode had some problems : ',
              error
            )
          )
        })
    }) // promise
  }

  getUdirFagKode(urlFag) {
    // from http://data.udir.no/kl06/MAT1Z34
    //  console.log('getUdirFagKode got called (%j)', urlFag)
    // console.log(urlFag)
    return new Promise((resolve, reject) => {
      let newUrl = fixHttpsUrl(urlFag['url-data'])
      this.getHTTPUdir(newUrl)
        .then((result) => {
          //    console.info('====== RESULT ====== getUdirFagKode')
          // console.log(result.data)
          let lareplanreferanse = result.data['laereplan-referanse'] || []
          for (let i = 0; i < lareplanreferanse.length; i++) {
            //          lareplanreferanse.forEach((value, index, array) => {
            let value = lareplanreferanse[i]
            let kms = value['tilhoerende-kompetansemaalsett']
            //   console.log('--- KMS --- ')
            //   console.log(kms)
            // console.log(value.status)
            if (checkValidLareplan(value)) {
              kms.forEach((value1) => {
                //     console.log('======= VALUE2 KODE (' + value1.kode + ')')
                resolve(value1.kode)
              })
            }
          } // for each
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** getUdirFagKode had some problems !' + error
          )
          reject(
            new Error('*** ERROR *** getUdirFagKode had some problems !', error)
          )
        })
    })
  }

  getGrepDataFromKompetansemaal(kms, currentLang) {
    // kms = testBrukerKms(kms)
    console.log(
      'getGREP DATA FROM KOMPETANSEMÅL got called (%s), lang(%s)',
      kms,
      currentLang
    )
    return new Promise((resolve, reject) => {
      let searchCode = GREPKompetanseMaalSett + '/' + kms
      //   console.log('SEARCH (%s)', this.udirAPIServer + GREPVersion + searchCode)
      this.getHTTPUdirLanguage(
        this.udirAPIServer + GREPVersion + searchCode,
        currentLang
      ) // code +
        .then((response) => {
          let grepData = response.data
          //  console.log(grepData)
          // console.log('=== GREP RESPONSE : ' + grepData.tittel[0].verdi)
          //  console.log(JSON.stringify(grepData.kompetansemaal))
          let fixedTitles = []
          if (currentLang !== 'nob') {
            // Default language from GREP is Norsk Bokmål
            console.log(
              'PROCESSING CURRICULUM INFORMATION FOR (s)',
              currentLang
            )
            for (const comp in grepData.kompetansemaal) {
              let competence = grepData.kompetansemaal[comp] || {}
              this.getLangTitle(competence['url-data'], currentLang).then(
                (cres) => {
                  grepData.kompetansemaal[comp].tittel = cres
                  fixedTitles.push(cres)
                  if (fixedTitles.length === grepData.kompetansemaal.length) {
                    resolve(grepData.kompetansemaal)
                  }
                }
              )
            }
          } else if (grepData !== undefined) {
            resolve(grepData.kompetansemaal)
          } else {
            reject(
              new Error(
                'getDataFromGREP: grepData is undefined kms(' + kms + ')'
              )
            )
          }
        })
        .catch((error) => {
          console.error('getDataFromGREP had some problems ! ' + error)
          reject(new Error('getDataFromGREP had some problems ! ' + error))
        })
    }) // promise
  }

  getSubjectUrlFromCode(code, currentLang) {
    return new Promise((resolve, reject) => {
      if (code === 'undefined') {
        resolve({
          succes: true,
          successMsg: 'no value for code: ' + code
        })
      }
      this.getHTTPUdirLanguage(
        this.udirAPIServer + GREPVersion + GREPKompetanseMaalSett + '/' + code,
        currentLang
      )
        .then((response) => {
          let grepData = response.data['tilhoerer-laereplan'] || {}
          // console.log(grepData)
          let urlData = grepData['url-data'] || ''
          if (urlData !== '') {
            urlData = urlData + '.html'
          }
          let subjectTitle = grepData.tittel || ''
          resolve({
            url: urlData,
            tittel: subjectTitle,
            kode: grepData.kode
          })
        })
        .catch((error) => {
          console.error(
            'getSubjectUrlFromCode had some problems ! ' +
              error +
              ' code : ' +
              code
          )
          reject(
            new Error(
              'getSubjectUrlFromCode had some problems ! ' +
                error +
                ' code : ' +
                code
            )
          )
        })
    }) // promise
  }

  getGrepCoreElements(url, currentLang) {
    return new Promise((resolve, reject) => {
      if (url === '' || url === undefined) {
        reject('NO URL PROVIDED !')
      }
      this.getHTTPUdirLanguage(url, currentLang)
        .then((response) => {
          /*   console.log(
            'GREP CORE ELEMENTS RETURNED (%s) (%j)',
            currentLang !== 'nob',
            response.data
          )
          */
          if (currentLang !== 'nob') {
            this.getCurriculaDetailLangV1(
              response.data['tilknyttede-kjerneelementer'],
              currentLang
            ).then((tkRes) => {
              response.data['tilknyttede-kjerneelementer'] = tkRes
              this.getCurriculaDetailLang(
                response.data['tilknyttede-grunnleggende-ferdigheter'],
                currentLang
              ).then((tgfRes) => {
                response.data['tilknyttede-grunnleggende-ferdigheter'] = tgfRes
                //    console.log('>>>>>>>>>>> TILKNYTTEDE VERB')
                this.getCurriculaDetailLangV2(
                  response.data['tilknyttede-verb'],
                  currentLang
                ).then((tvRes) => {
                  response.data['tilknyttede-verb'] = tvRes
                  //     console.log('>>>>>>>>>>> BYGGER PÅ')
                  this.getCurriculaDetailLangV1(
                    response.data['bygger-paa'],
                    currentLang
                  ).then((bpRes) => {
                    response.data['bygger-paa'] = bpRes
                    /*        console.log(
                      '>>>>>>>>>>> SAMMES SOM (%j)',
                      response.data['samme-som']
                    )
                    */
                    this.getCurriculaDetailLang(
                      response.data['samme-som'],
                      currentLang
                    )
                      .then((ssRes) => {
                        //             console.log('SAMME SOM RETURNED (%j)', ssRes)
                        response.data['samme-som'] = ssRes
                      })
                      .finally(() => {
                        resolve(response)
                      })
                  })
                })
              })
            })
          } else {
            resolve(response)
          }
        })
        .catch((error) => {
          console.error(
            'getGrepCoreElements had some problems ! ' + error + ' code : '
          )
          reject(
            new Error(
              'getGrepCoreElements had some problems ! ' + error + ' code : '
            )
          )
        })
    }) // promise
  }

  getDefinitionsFromGrep(url, currentLang) {
    return new Promise((resolve, reject) => {
      if (url === '' || url === undefined) {
        reject('NO URL PROVIDED !')
      }
      this.getHTTPUdirLanguage(url, currentLang)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(
            'getDefinitionsFromGrep had some problems ! ' + error + ' code : '
          )
          reject(
            new Error(
              'getDefinitionsFromGrep had some problems ! ' + error + ' code : '
            )
          )
        })
    }) // promise
  }

  getByggerPaaElements(url, currentLang) {
    return new Promise((resolve, reject) => {
      if (url === '' || url === undefined) {
        reject('NO URL PROVIDED !')
      }
      this.getHTTPUdirLanguage(url, currentLang)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(
            'getGrepCoreElements had some problems ! ' + error + ' code : '
          )
          reject(
            new Error(
              'getGrepCoreElements had some problems ! ' + error + ' code : '
            )
          )
        })
    }) // promise
  }

  getLangTitle(url, webLang) {
    return new Promise((resolve) => {
      this.getHTTPUdirLanguage(url, webLang).then((result) => {
        //     console.log('getLangTitle returned: (%s) (%j)', url, result.data)
        resolve(result.data.tittel.tekst[0].verdi)
        // resolve('url')
      })
    }) // promise
  }
  getLangDetailTitle(url, webLang) {
    return new Promise((resolve) => {
      this.getHTTPUdirLanguage(url, webLang).then((result) => {
        //    console.log('getLangTitle returned: (%j)', result)
        resolve(result.data.tittel[0].verdi)
        // resolve('url')
      })
    }) // promise
  }

  getCurriculaDetailLang(curriculaObject, currentLang) {
    return new Promise((resolve) => {
      let objLength = curriculaObject.length
      if (objLength === 0) {
        resolve([])
      }
      let updatedObject = []
      for (let curriculaItem in curriculaObject) {
        let curr = curriculaObject[curriculaItem]
        let ref = curr.referanse
        let urlData = ref['url-data']
        this.getLangDetailTitle(urlData, currentLang).then((newTitle) => {
          curr.referanse.tittel = newTitle
          updatedObject.push(curr)
          if (objLength === updatedObject.length) {
            resolve(updatedObject)
          }
        })
      }
    }) // promise
  }

  getCurriculaDetailLangV1(curriculaObject, currentLang) {
    return new Promise((resolve) => {
      let objLength = curriculaObject.length
      if (objLength === 0) {
        resolve([])
      }
      let updatedObject = []
      for (let curriculaItem in curriculaObject) {
        let curr = curriculaObject[curriculaItem]
        let ref = curr.referanse
        let urlData = ref['url-data']
        //    console.log('URL DATA (%s)', urlData)
        this.getLangTitle(urlData, currentLang).then((newTitle) => {
          curr.referanse.tittel = newTitle
          updatedObject.push(curr)
          if (objLength === updatedObject.length) {
            resolve(updatedObject)
          }
        })
      }
    }) // promise
  }

  getCurriculaDetailLangV2(curriculaObject, currentLang) {
    //  console.log('getCurriculaDetailLangV2 GOT CALLED')
    //  console.log(curriculaObject)
    return new Promise((resolve) => {
      let objLength = curriculaObject.length
      if (objLength === 0) {
        resolve([])
      }
      let updatedObject = []
      for (let curriculaItem in curriculaObject) {
        let curr = curriculaObject[curriculaItem]
        // let ref = curr.referanse
        let urlData = curr['url-data']
        //  console.log('URL DATA (%s)', urlData)
        this.getLangDetailTitle(urlData, currentLang).then((newTitle) => {
          curr.tittel = newTitle
          updatedObject.push(curr)
          if (objLength === updatedObject.length) {
            resolve(updatedObject)
          }
        })
      }
    }) // promise
  }

  async getHTTPUdir(url) {
    return new Promise((resolve, reject) => {
      console.log('GET getHTTPUdir URL (%s)', url)
      let options = {
        method: 'GET',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        mode: 'cors',
        // timeout: 30000, // 30s // controlled by the browser
        responseType: 'json', // default
        responseEncoding: 'utf8', // default
        maxRedirects: 3, // default
        'Accept-Encoding': 'gzip, compress'
        // body: "{foo: 'bar'}" //JSON.stringify(loadData),
      }

      fetch(options.url, options)
        .then((response) => {
          console.log('getHTTPUdir RESPONSE IS (%j)', response)
          response
            .json()
            .then((result) => {
              console.log('RESULT FROM JSON IS (%j)', result)
              resolve({ success: true, data: result })
            })
            .catch((err2) => {
              console.error('RESPONSE JSON', err2)
            })
        })
        .catch((error) => {
          console.error('*** ERROR *** getHTTPFeide', {
            errorMsg: error.toString()
          })
          reject({ success: false, error: true, errorMsg: error.toString() })
        })
    }) // promise
  }

  async getHTTPUdirLanguage(url, currentLang) {
    return new Promise((resolve, reject) => {
      console.log('GET getHTTPUdir URL (%s)', url)
      let options = {
        method: 'GET',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        params: {
          lang: currentLang
        },
        mode: 'cors',
        // timeout: 30000, // 30s // controlled by the browser
        responseType: 'json', // default
        responseEncoding: 'utf8', // default
        maxRedirects: 3, // default
        'Accept-Encoding': 'gzip, compress'
        // body: "{foo: 'bar'}" //JSON.stringify(loadData),
      }

      fetch(options.url, options)
        .then((response) => {
          console.log('getHTTPUdir RESPONSE IS (%j)', response)
          response
            .json()
            .then((result) => {
              console.log('RESULT FROM JSON IS (%j)', result)
              resolve({ success: true, data: result })
            })
            .catch((err2) => {
              console.error('RESPONSE JSON', err2)
            })
        })
        .catch((error) => {
          console.error('*** ERROR *** getHTTPFeide', {
            errorMsg: error.toString()
          })
          reject({ success: false, error: true, errorMsg: error.toString() })
        })
    }) // promise
  }
}

function fixHttpsUrl(url) {
  //  console.log('=== fixHttpsUrl === got called (' + url + ')')
  let coreUrl = url.substring(0, 5)
  if (coreUrl === 'https') {
    return url
  } else if (coreUrl === 'http:') {
    return url.replace('http:', 'https:')
  }
}

function checkValidLareplan(lareplan) {
  // console.log('CHECK VALID LAREPLAN GOT CALLED ')
  // console.log(JSON.stringify(lareplan))
  let ks = lareplan['tilhoerende-kompetansemaalsett'] || []
  // console.log(JSON.stringify(ks))
  let kompetansesett = ks[0]
  // console.log(JSON.stringify(kompetansesett))
  let today = dayjs()
  let fromDate = kompetansesett.gyldighet['gyldig-fra'] || '0000-00-00'
  let toDate = kompetansesett.gyldighet['gyldig-til'] || '0000-00-00'
  /*
  console.log('CHECKVALID LAREPLAN:::')
  console.log(today)
  console.log(fromDate)
  console.log(toDate)
  */
  let validFrom = dayjs(fromDate)
  let validTo = dayjs(toDate)
  // console.log(validFrom)
  // console.log(validTo)
  if (kompetansesett.status !== GREPStatusPublisert) {
    // console.log('>>>>>>>>> NOT PUBLISHED <<<<<<<<<<<')
    return false
  } else {
    // status is publisert...
    if (today.isSameOrAfter(validFrom) && validTo.isSameOrAfter(today)) {
      /* console.log(
        'TEST 1 (%s)',
        today.isSameOrAfter(validFrom) && validTo.isSameOrAfter(today)
      )
*/
      return true
    } else if (today.isSameOrAfter(validFrom) && toDate === '0000-00-00') {
      /*  console.log(
        'TEST 2 (%s)',
        today.isSameOrAfter(validFrom) && toDate === '0000-00-00'
      )
      */
      return true
    } else {
      //  console.log('<<<<<<<<<<<<<<<<< F AL S E >>>>>>>>>>>>>')
      return false
    }
  }
}

function checkValidOpplaeringsfag(lareplan) {
  // console.log('CHECK VALID OPPLAERINGSFAG GOT CALLED ')
  // console.log(JSON.stringify(lareplan))
  if (lareplan.status !== GREPStatusPublisert) {
    console.log('>>>>>>>>> NOT PUBLISHED <<<<<<<<<<<')
    return false
  } else {
    let today = dayjs()
    let fromDate = lareplan.gyldighet['gyldig-fra'] || '0000-00-00'
    let toDate = lareplan.gyldighet['gyldig-til'] || '0000-00-00'
    /*  console.log('CHECKVALID LAREPLAN:::')
    console.log(today)
    console.log(fromDate)
    console.log(toDate)
    */
    let validFrom = dayjs(fromDate)
    let validTo = dayjs(toDate)
    // console.log(validFrom)
    // console.log(validTo)

    // status is publisert...
    if (today.isSameOrAfter(validFrom) && validTo.isSameOrAfter(today)) {
      /*  console.log(
        'TEST 1 (%s)',
        today.isSameOrAfter(validFrom) && validTo.isSameOrAfter(today)
      )
      */
      return true
    } else if (today.isSameOrAfter(validFrom) && toDate === '0000-00-00') {
      /* console.log(
        'TEST 2 (%s)',
        today.isSameOrAfter(validFrom) && toDate === '0000-00-00'
      )
      */
      return true
    } else if (
      fromDate === '0000-00-00' &&
      toDate === '0000-00-00' &&
      lareplan.status === GREPStatusPublisert
    ) {
      return true
    } else {
      console.log('<<<<<<<<<<<<<<<<< F AL S E >>>>>>>>>>>>>')
      return false
    }
  }
}

function testBrukerKms(kms) {
  let osHostname = os.hostname()
  console.log('TEST_ENVIRONMENT IS(%s) ', osHostname)
  if (
    osHostname === 'stage-l.ataa.no' ||
    osHostname === 'saeducator.hypatia.no'
  ) {
    switch (kms) {
      case 'KMS911': // Engelsk 4kl
        return 'KV2'
      case 'KMS928': // Matematikk 4kl
        return 'KV18'
      case 'KMS921': // Naturfag 4kl
        return 'KV80'
      case 'KMS944': // Norsk 4kl
        return 'KV109'
      case 'KMS939': // Samfunnsfag 4kl
        return 'KV145'
      default:
        return kms
    }
  }
  return kms
}
