/* Class and set of functions for communication with Feide and Dataporten */
import { jwtDecode } from 'jwt-decode'

export class FeideTools {
  constructor(accessToken, idToken) {
    console.log(
      '-------------FeideTools---------- Constructor : (' + accessToken + ')'
    )
    // console.log(idToken)
    this.token = accessToken
    this.idToken = idToken
    this.feideUserServer = 'https://api.dataporten.no'
    this.feideGroupServer = 'https://groups-api.dataporten.no'
    this.feideAuthServer = 'https://auth.dataporten.no'
    this.feideAPIServer = 'https://api.feide.no'
    this.udirAPIServer = 'https://data-nsr.udir.no'
  }

  isLoggedIn(clientToken) {
    // console.error('=== jwtToken === (' + clientToken + ')')
    if (clientToken === undefined) {
      /*
      throw new Error({
        error: 'Not logged in (no client token) !'
      })
      */
      return false
    }
    let jwtToken = jwtDecode(clientToken)
    // console.error('=== jwtToken')
    // console.log(jwtToken)
    let tokenExpire = jwtToken.exp
    // console.log('isLoggedIn: tokenExpire (' + tokenExpire + ')')
    if (isTokenExpired(tokenExpire)) {
      /*
      throw new Error({
        error: 'Not logged in (token expired) !'
      })
      */
      return false
    }
    return true
  }
  async getHTTPFeide(url) {
    return new Promise((resolve, reject) => {
      console.log('GET getHTTPFeide URL (%s) (%s)', url, this.token)
      let options = {
        method: 'GET',
        url: url,
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        // mode: 'no-cors',
        // credentials: "include",
        // timeout: 30000, // 30s // controlled by the browser
        responseType: 'json', // default
        responseEncoding: 'utf8', // default
        maxRedirects: 5, // default
        'Accept-Encoding': 'gzip, compress'
        // body: "{foo: 'bar'}" //JSON.stringify(loadData),
      }

      fetch(options.url, options)
        .then((response) => {
          console.log('RESPONSE IS (%j)', response)
          response
            .json()
            .then((result) => {
              console.log('RESULT FROM JSON IS (%j)', result)
              resolve({ success: true, data: result })
            })
            .catch((err2) => {
              console.error('RESPONSE JSON', err2)
            })
        })
        .catch((error) => {
          console.error('*** ERROR *** getHTTPFeide', {
            errorMsg: error.toString()
          })
          reject({ success: false, error: true, errorMsg: error.toString() })
        })
    }) // promise
  }

  async getHTTPUdir(url) {
    return new Promise((resolve, reject) => {
      console.log('GET getHTTPUdir URL (%s)', url)
      let options = {
        method: 'GET',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        mode: 'no-cors',
        // timeout: 30000, // 30s // controlled by the browser
        responseType: 'json', // default
        responseEncoding: 'utf8', // default
        maxRedirects: 3, // default
        'Accept-Encoding': 'gzip, compress'
        // body: "{foo: 'bar'}" //JSON.stringify(loadData),
      }

      fetch(options.url, options)
        .then((response) => {
          console.log('getHTTPUdir RESPONSE IS (%j)', response)
          response
            .json()
            .then((result) => {
              console.log('RESULT FROM JSON IS (%j)', result)
              resolve({ success: true, data: result })
            })
            .catch((err2) => {
              console.error('RESPONSE JSON', err2)
            })
        })
        .catch((error) => {
          console.error('*** ERROR *** getHTTPFeide', {
            errorMsg: error.toString()
          })
          reject({ success: false, error: true, errorMsg: error.toString() })
        })
    }) // promise
  }

  getUserInfo() {
    return new Promise((resolve, reject) => {
      // console.log('getUserInfo got called ! : ' + this.token)
      this.getHTTPFeide(this.feideAuthServer + '/openid/userinfo')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          console.error('*** ERROR *** getUserInfo:' + error)
          reject(new Error('*** ERROR *** getUserInfo:' + error))
        })
    })
  }

  getBasicUserInfo() {
    // let serverUrl = 'https://api.dataporten.no'
    console.log('getBasicUserInfo got called:')
    return new Promise((resolve, reject) => {
      this.getHTTPFeide(this.feideUserServer + '/userinfo/v1/userinfo')
        .then((response) => {
          console.log('basicUserInfo is:')
          console.log(response.data)
          resolve(response.data)
        })
        .catch((error) => {
          console.error('getBasicUserInfo had problems !: ' + error)
          reject(new Error('getBasicUserInfo had problems !: ' + error))
        })
    })
  }

  /* ********* GROUPS ********** */
  /* https://docs.feide.no/developer_oauth/technical_details/groups_api.html */
  getAllGroups() {
    return new Promise((resolve, reject) => {
      // console.log('getAllGroups got called ! : ' + this.token)
      this.getHTTPFeide(this.feideGroupServer + '/groups/me/groups')
        .then((response) => {
          resolve(response.data)
          //      resolve(karlaData())
        })
        .catch((error) => {
          console.error('*** ERROR *** getAllGroups:' + error)
          reject(new Error('*** ERROR *** getAllGroups:' + error))
        })
    })
  }

  getGroupMembers(groupID) {
    return new Promise((resolve, reject) => {
      // console.log('getAllGroups got called ! : ' + this.token)
      this.getHTTPFeide(
        this.feideGroupServer + '/groups/groups/' + groupID + '/members'
      )
        .then((response) => {
          // console.log(response.data)
          resolve(response.data)
        })
        .catch((error) => {
          console.error('*** ERROR *** getGroupMembers:' + error)
          reject(new Error('*** ERROR *** getGroupMembers:' + error))
        })
    })
  }

  getAllOrganisations() {
    console.log('getAllOrganisations got called')
    return new Promise((resolve, reject) => {
      // console.log('getAllOrganisations got called ! : ' + this.token)
      this.getHTTPUdir(this.udirAPIServer + '/kommuner')
        .then((response) => {
          let orgs = response.data
          let activeOrgs = []
          orgs.forEach((value) => {
            if (!value.ErNedlagt && value.OrgNr) {
              activeOrgs.push({
                kommunenavn: value.Navn,
                kommuneorgnr: value.OrgNr
              })
            }
          })
          // console.log('got the following Active organisations')
          // console.log(activeOrgs)
          resolve(activeOrgs)
        })
        .catch((error) => {
          console.error('*** ERROR *** getAllOrganisations:' + error)
          reject(new Error('*** ERROR *** getAllOrganisations:' + error))
        })
    })
  }

  getFeideShcools(kommuneorgnr) {
    // console.log('getFeideShcools got called (' + kommuneorgnr + ')')
    return new Promise((resolve, reject) => {
      if (kommuneorgnr === undefined) {
        reject(new Error('NO kommunenr defined'))
      }
      let kommunenr = kommuneorgnr.substr(2)
      // /trestruktur/940039541/1
      let query = '/trestruktur/' + kommunenr + '/1'
      this.getHTTPUdir(this.udirAPIServer + query)
        .then((response) => {
          // console.log(response.data.Children)
          let schools = response.data.Children
          let returnSchools = []
          schools.forEach((value) => {
            returnSchools.push({
              skolenavn: value.Navn,
              skoleorgnr: value.OrgNr
            })
          })
          // console.log('got the following Active organisations')
          // console.log(activeOrgs)
          resolve(returnSchools)
        })
        .catch((error) => {
          console.error('*** ERROR *** getAllOrganisations:' + error)
          reject(new Error('*** ERROR *** getAllOrganisations:' + error))
        })
    }) // promise
  }

  /* Method for extracting "groups" of a specific type [u,b] */
  getFeideGroups(groups, type) {
    console.log('getFeideGroups (%s) (%s)', groups.length, type)
    // console.log(groups)
    let fsType = ''
    /* when logging in from HE, the group model have different attributes.
     U -> Fag 
     B -> Grupper/Klasser
     For HE vi må også sjekke medlemsskapet til gruppene. Noe som avgjør om det er  U eller B.
     K13 har go_type="a","b","u" - Det virker som om HE ikke har disse.
    */
    let feideGroups = []
    let length = groups.length || 0
    for (let i = 0; i < length; i++) {
      let item = groups[i] || {}
      if (item.go_type && item.go_type === type) {
        feideGroups.push(item)
      }
    }
    if (feideGroups.length === 0) {
      // School K13 was found - checking HE
      if (type === 'u') {
        // U FAG
        for (let i = 0; i < length; i++) {
          let item = groups[i] || {}
          if (item.membership.active && item.type === 'fc:fs:emne') {
            if (item.membership.basic === 'owner') {
              feideGroups.push(item)
            }
          }
        }
      } else if (type === 'b') {
        // B GRUPPER
        for (let i = 0; i < length; i++) {
          let item = groups[i] || {}
          if (
            item.membership.active &&
            (item.type === 'fc:fs:prg' ||
              item.type === 'fc:fs:kull' ||
              item.type === 'fc:fs:klasse')
          ) {
            if (item.membership.basic === 'owner') {
              feideGroups.push(item)
            }
          }
        }
      }
    }
    return feideGroups
  }

  getFeideOrgGroups(groups) {
    // console.log('getFeideOrgGroups')
    //  console.log(groups)
    let feideOrgGroups = []
    // groups.forEach((value, index, array) => {
    groups.forEach((value) => {
      if (
        value !== undefined &&
        value.type !== undefined &&
        value.type === 'fc:org'
      ) {
        console.log('==== WE HAVE FC:ORG === ' + value.type)
        // console.log(value)
        feideOrgGroups.push(value)
      }
    })
    return feideOrgGroups
  }

  getFeideEnrichUGroup(uGroups) {
    console.log('getFeideEnrichUGroup got called')
    //  let enrichedGroups = []
    //    uGroups.forEach((value, index, array) => {
    uGroups.forEach((value) => {
      console.error('*** NOT USED *** getFeideEnrichUGroup (%j)', value)
    })
  }

  getFeideNorEduOrgNin(groups) {
    console.log('getFeideNorEduOrgNin got called')
    let municipalityNorEduOrgNin = ''
    let municipalityDisplayName = ''
    let schoolNorEduOrgNin = ''
    let schoolDisplayName = ''
    //    groups.forEach((value, index) => {
    groups.forEach((value) => {
      if (value.type === 'fc:org') {
        // console.log('================ FC:ORG ==========')
        // console.log(JSON.stringify(value))
        if (value.orgType[0] === 'primary_and_lower_secondary_owner') {
          municipalityNorEduOrgNin = value.norEduOrgNIN
          municipalityDisplayName = value.displayName
          schoolNorEduOrgNin = value.norEduOrgNIN
        } else if (value.orgType[0] === 'higher_education') {
          municipalityNorEduOrgNin = value.norEduOrgNIN
          municipalityDisplayName = value.displayName
          schoolDisplayName = value.displayName
          schoolNorEduOrgNin = value.norEduOrgNIN
        } else if (value.orgType[0] === 'primary_and_lower_secondary') {
          schoolDisplayName = value.displayName
          let id = value.id
          let idSplit = id.split(':')
          // console.log(idSplit)
          schoolNorEduOrgNin = idSplit[4]
        }
      }
    })
    return {
      municipalityNorEduOrgNin: municipalityNorEduOrgNin,
      municipalityDisplayName: municipalityDisplayName,
      schoolNorEduOrgNin: schoolNorEduOrgNin,
      schoolDisplayName: schoolDisplayName
    }
  }

  getFeideSchoolClass(bGroups) {
    /* fc:gogroup:spusers.feide.no:b:NO856326499:10a:2016-01-01:2019-06-20 */
    let bId = ''
    //    bGroups.forEach((value, index) => {
    bGroups.forEach((value) => {
      bId = value.id
    })
    console.log('=== BID === (' + bId + ')')
    let idSplit = bId.split(':')
    let eduNIN = idSplit[5] || ''
    return eduNIN.toUpperCase()
  }
}

export function isAuthenticated() {
  let tokenExpiration = localStorage.getItem('tokenexpire')
  return !!tokenExpiration && tokenExpiration < new Date()
}

function isTokenExpired(token) {
  let expirationDate = getTokenExpirationDate(token)
  return expirationDate < new Date()
}

function getTokenExpirationDate(encodedToken) {
  let date = new Date(0)
  date.setUTCSeconds(encodedToken)
  return date
}

function karlaData() {
  return [
    {
      id: 'fc:org:hiof.no',
      type: 'fc:org',
      displayName: 'Høgskolen i Østfold',
      membership: {
        basic: 'admin',
        displayName: 'Akademisk ansatt',
        affiliation: ['member', 'student', 'employee', 'faculty'],
        primaryAffiliation: 'faculty',
        title: ['Stipendiat']
      },
      public: false,
      orgType: ['higher_education'],
      eduOrgHomePageURI: 'http://www.hiof.no/',
      eduOrgLegalName: 'Høgskolen i Østfold',
      facsimileTelephoneNumber: '+47 69 60 80 01',
      l: 'Østfold',
      labeledURI: 'http://www.hiof.no/',
      mail: 'postmottak@hiof.no',
      norEduOrgNIN: 'NO971567376',
      norEduOrgUniqueIdentifier: '00000224',
      postalAddress: 'Høgskolen i Østfold, Postboks 700, 1757 Halden',
      street: 'B R A veien 4, Remmen',
      telephoneNumber: '+47 69 60 80 00'
    },
    {
      id: 'fc:org:hiof.no:unit:350201',
      type: 'fc:orgunit',
      displayName: 'LUS-STIP',
      membership: { basic: 'member', primaryOrgUnit: true },
      public: false,
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:org:hiof.no:unit:650000',
      type: 'fc:orgunit',
      displayName: 'FA-IIO',
      membership: { basic: 'member', primaryOrgUnit: false },
      public: false,
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:fs:fs:prg:hiof.no:MAGLU5-10',
      type: 'fc:fs:prg',
      displayName: 'Grunnskolelærerutdanning for trinn 5-10 (300 studiepoeng)',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER']
      },
      parent: 'fc:org:hiof.no',
      url: 'https://www.hiof.no/studier/programmer/maglu5-10-grunnskolelererutdanning-for-trinn-510/index.html'
    },
    {
      id: 'fc:fs:fs:emne:hiof.no:PHDDS90123:1',
      type: 'fc:fs:emne',
      displayName: 'Digitalisering og samfunn',
      membership: {
        basic: 'member',
        active: true,
        displayName: 'Student',
        fsroles: ['STUDENT']
      },
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:fs:fs:emne:hiof.no:LSKDIGK319:1',
      type: 'fc:fs:emne',
      displayName:
        'Profesjonsfaglig digital kompetanse for engelsklærere i videregående opplæring',
      membership: {
        basic: 'member',
        active: true,
        displayName: 'Student',
        fsroles: ['STUDENT'],
        notAfter: '2021-08-14T22:00:00Z',
        subjectRelations: 'undervisning'
      },
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:fs:fs:emne:hiof.no:LSKDIGK119:1',
      type: 'fc:fs:emne',
      displayName:
        'Profesjonsfaglig digital kompetanse for lærere i videregående opplæring',
      membership: {
        basic: 'member',
        active: true,
        displayName: 'Student',
        fsroles: ['STUDENT'],
        notAfter: '2020-12-14T23:00:00Z',
        subjectRelations: 'undervisning'
      },
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:fs:fs:prg:hiof.no:EKSPHD',
      type: 'fc:fs:prg',
      displayName: 'Enkeltemner på Ph.d.-program',
      membership: {
        basic: 'member',
        active: true,
        displayName: 'Student',
        fsroles: ['STUDENT'],
        notAfter: '2023-09-03T22:00:00Z'
      },
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:fs:fs:prg:hiof.no:MAGLU1-7',
      type: 'fc:fs:prg',
      displayName: 'Grunnskolelærerutdanning for trinn 1-7 (300 studiepoeng)',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER']
      },
      parent: 'fc:org:hiof.no',
      url: 'https://www.hiof.no/studier/programmer/maglu1-7-grunnskolelererutdanning-for-trinn-17/index.html'
    },
    {
      id: 'fc:fs:fs:emne:hiof.no:LMUENG10221:1',
      type: 'fc:fs:emne',
      displayName:
        'ENG102 Kultur, samfunn, litteratur i den engelskspråklige verden 1 (5-10)',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer, Hovedlærer, Emneansvarlig, Intern sensor',
        fsroles: ['LÆRER', 'HOVEDLÆRER', 'EMNEANSV', 'INTERN']
      },
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:fs:fs:kull:hiof.no:MAGLU5-10:2023H',
      type: 'fc:fs:kull',
      displayName:
        'Kull for Høst 2023 Grunnskolelærerutdanning for trinn 5-10 (300 studiepoeng)',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER']
      },
      parent: 'fc:fs:fs:prg:MAGLU5-10'
    },
    {
      id: 'fc:fs:fs:klasse:hiof.no:MAGLU5-10:2021H:null',
      type: 'fc:fs:klasse',
      displayName:
        'Klasse Grunnskolelærerutdanning for trinn 5-10 (300 studiepoeng) null Høst 2021',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER']
      },
      parent: 'fc:fs:fs:kull:hiof.no:MAGLU5-10:2021H'
    },
    {
      id: 'fc:fs:fs:emne:hiof.no:LMUENG10417:1',
      type: 'fc:fs:emne',
      displayName:
        'ENG104 Kultur, samfunn, litteratur i den engelskspråklige verden (5-10)',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer, Intern sensor',
        fsroles: ['LÆRER', 'INTERN']
      },
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:fs:fs:emne:hiof.no:LMBENG10122:1',
      type: 'fc:fs:emne',
      displayName: 'ENG101 Engelsk språk og kommunikasjon (1-7)',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Innsyn',
        fsroles: ['INNSYN']
      },
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:fs:fs:klasse:hiof.no:MAGLU5-10:2022H:null',
      type: 'fc:fs:klasse',
      displayName:
        'Klasse Grunnskolelærerutdanning for trinn 5-10 (300 studiepoeng) null Høst 2022',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER']
      },
      parent: 'fc:fs:fs:kull:hiof.no:MAGLU5-10:2022H'
    },
    {
      id: 'fc:fs:fs:emne:hiof.no:PHDDS90223:1',
      type: 'fc:fs:emne',
      displayName: 'Vitenskapsfilosofi og forskningsetikk',
      membership: {
        basic: 'member',
        active: true,
        displayName: 'Student',
        fsroles: ['STUDENT']
      },
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:fs:fs:klasse:hiof.no:MAGLU1-7:2021H:null',
      type: 'fc:fs:klasse',
      displayName:
        'Klasse Grunnskolelærerutdanning for trinn 1-7 (300 studiepoeng) null Høst 2021',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER']
      },
      parent: 'fc:fs:fs:kull:hiof.no:MAGLU1-7:2021H'
    },
    {
      id: 'fc:fs:fs:kull:hiof.no:MAGLU5-10:2022H',
      type: 'fc:fs:kull',
      displayName:
        'Kull for Høst 2022 Grunnskolelærerutdanning for trinn 5-10 (300 studiepoeng)',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER']
      },
      parent: 'fc:fs:fs:prg:MAGLU5-10'
    },
    {
      id: 'fc:fs:fs:kull:hiof.no:MAGLU5-10:2021H',
      type: 'fc:fs:kull',
      displayName:
        'Kull for Høst 2021 Grunnskolelærerutdanning for trinn 5-10 (300 studiepoeng)',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER']
      },
      parent: 'fc:fs:fs:prg:MAGLU5-10'
    },
    {
      id: 'fc:fs:fs:kull:hiof.no:MAGLU1-7:2021H',
      type: 'fc:fs:kull',
      displayName:
        'Kull for Høst 2021 Grunnskolelærerutdanning for trinn 1-7 (300 studiepoeng)',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER']
      },
      parent: 'fc:fs:fs:prg:MAGLU1-7'
    },
    {
      id: 'fc:fs:fs:emne:hiof.no:LMBENG10217:1',
      type: 'fc:fs:emne',
      displayName:
        'ENG102 Kultur, samfunn, litteratur i den engelskspråklige verden 1 (1-7)',
      membership: {
        basic: 'member',
        active: true,
        displayName: 'Intern sensor',
        fsroles: ['INTERN']
      },
      parent: 'fc:org:hiof.no'
    },
    {
      id: 'fc:fs:fs:klasse:hiof.no:MAGLU5-10:2023H:null',
      type: 'fc:fs:klasse',
      displayName:
        'Klasse Grunnskolelærerutdanning for trinn 5-10 (300 studiepoeng) null Høst 2023',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER']
      },
      parent: 'fc:fs:fs:kull:hiof.no:MAGLU5-10:2023H'
    },
    {
      id: 'fc:fs:fs:emne:hiof.no:LPENG216:1',
      type: 'fc:fs:emne',
      displayName: 'Engelsk, didaktikk 2',
      membership: {
        basic: 'owner',
        active: true,
        displayName: 'Lærer',
        fsroles: ['LÆRER'],
        subjectRelations: 'undervisning'
      },
      parent: 'fc:org:hiof.no'
    }
  ]
}
