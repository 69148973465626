import { createI18n } from 'vue-i18n'

import nb from './lang/nb.json'
import en from './lang/en.json'

const i18nMessages = {
  en,
  nb
}

console.log('i18nMessages')

// const defaultLanguage = 'en'
const i18n = createI18n({
  locale: 'nb', // defaultLanguage, // set locale
  fallbackLocale: 'en',
  globalInjection: true,
  messages: {
    nb: i18nMessages.nb,
    en: i18nMessages.en
  } // set locale messages
})

export default i18n
